import React, { useEffect } from 'react';
import BGImg7 from "../../assets/img/bg/test-bg.png";
import Slider from "react-slick";
import Icon from "../../assets/img/testimonial/qt-icon.png";

const Testimonial = () => {
  const testimonial = {
    initialIndex: 2,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 900,
    autoPlay: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // Create the script element for the Salonized widget
    const script = document.createElement('script');
    script.src = 'https://cdn.salonized.com/widget.js';
    script.setAttribute('data-name', 'salonized');
    script.setAttribute('data-microsite-url', 'https://amani-beauty-skin.salonized.com');
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <section
        className="testimonial-area pt-120 p-relative fix"
        style={{
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${BGImg7})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div
                className="section-title mb-50 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>Onze cliënten review</h5>
                <h3>Wat Onze cliënten Zeggen</h3>
                <p className="mt-15">
                  We zijn ontzettend trots op de relaties die we hebben opgebouwd
                  met onze klanten en het vertrouwen dat ze in ons hebben gesteld.
                  Hun feedback dient als een constante herinnering aan onze toewijding
                  om uitzonderlijke service te bieden.
                </p>
                <div className="slider-btn mt-20 pb-30 pl-40 pt-30 mt-10" style={{ marginLeft: "-33px" }}>
                  <a
                    href="https://g.page/r/Ce5JwlauCfAqEBM/review"
                    className="btn ss-btn mr-15"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-animation="fadeInUp"
                    data-delay=".8s"
                  >
                    Beoordeel Ons 
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <Slider className="testimonial-active" {...testimonial}>
                <div className="single-testimonial">
                  <div className="testi-author">
                    <img src={require('../../assets/img/testimonial/aya.png')} alt="img" />
                    <div className="ta-info">
                      <h6>Aya Aya</h6>
                      <span>Client</span>
                    </div>
                  </div>
                  <p>
                    “De behandeling was professioneel uitgevoerd 
                    en ik voelde me helemaal ontspannen achteraf. 
                    Ik kan deze salon ten zeerste aanbevelen aan 
                    iedereen die op zoek is naar hoogwaardige 
                    schoonheidsbehandelingen”.
                  </p>
                  <div className="qt-img">
                    <img src={Icon} alt="img" />
                  </div>
                </div>
                <div className="single-testimonial">
                  <div className="testi-author">
                    <img src={require('../../assets/img/testimonial/nour.png')} alt="img" />
                    <div className="ta-info">
                      <h6>Nourhan Rasho</h6>
                      <span>Client</span>
                    </div>
                  </div>
                  <p>
                    “De ervaring was geweldig en ik raad iedereen aan 
                    om het te bezoeken”.
                  </p>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="qt-img">
                    <img src={Icon} alt="img" />
                  </div>
                </div>
                <div className="single-testimonial">
                  <div className="testi-author">
                    <img src={require('../../assets/img/testimonial/roul.png')} alt="img" />
                    <div className="ta-info">
                      <h6>Roula Shihabi</h6>
                      <span>Client</span>
                    </div>
                  </div>
                  <p>
                    “Het was een zeer nuttige ervaring en ik ben er erg blij mee”.
                  </p>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div className="qt-img">
                    <img src={Icon} alt="img" />
                  </div>
                </div>
                <div className="single-testimonial">
                  <div className="testi-author">
                    <img src={require('../../assets/img/testimonial/Hai.png')} alt="img" />
                    <div className="ta-info">
                      <h6>Hayma Al bukhari</h6>
                      <span>Client</span>
                    </div>
                  </div>
                  <p>
                    “Zorgvuldige en vriendelijke behandeling met goed resultaat. 
                    Alles gaat in goed overleg en de afspraken zijn heel duidelijk. 
                    Blij mee”.
                  </p>
                  <br></br>
                  <br></br>
                  <div className="qt-img">
                    <img src={Icon} alt="img" />
                  </div>
                </div>
              </Slider>
              <div className="salonized-reviews-mini" data-link="https://amani-beauty-skin.salonized.com/reviews"></div> {/* Added the Salonized reviews widget div */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
