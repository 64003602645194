import React from 'react'
import {Link} from 'react-router-dom'
import Bredcrumb from '../../HomeMain/Bredcrumb'


const Microdermabrasie = () => {
    return (
        <>
            <Bredcrumb
                title="Microdermabrasie"
                subtitle="Microdermabrasie
"/>

            <div className="container">
                <div className="row justify-content-center align-items-center mt-55">
                    <div className="service-detail">
                        <div className="content-box">
                            <h2> WAT IS MICRODERMABRASIE?
                            </h2>
                            <p>
                                MICRODERMABRASIE zorgt ervoor dat de aanwezige onzuiverheden zoals puistjes, mee-eters
                                en gerstekorrels handmatig worden geledigd.
                                Rimpeltjes, acné en pigmentverkleuringen kun je behandelen met onze microdermabrasie. In
                                feite wordt de huid "geslepen", er wordt een zeer dun laagje huid verwijdert, waaronder
                                een nieuwe, frisse huidlaag zit. Tevens wordt de doorbloeding door de zuigende werking
                                gestimuleerd: de huid krijgt weer een gezonde tint door de gestimuleerde doorbloeding.
                            </p>
                            <div className="two-column">
                                <div className="row">
                                    <div className="text-column col-xl-12 col-lg-12 col-md-12">
                                        <figure className="image"><img src="assets/img/blog/micro.jpg"/></figure>
                                    </div>
                                </div>
                            </div>
                            <h3>Waarom deze behandeling?</h3>
                            <p>U kunt deze behandeling onder meer succesvol toepassen bij een:
                                <li>-futloze huid</li>
                                <li>-droge huid</li>
                                <li>-ruwe huid</li>
                                <li>-pigmentvlekken</li>
                                <li>-zonbeschadigde huid</li>
                                <li>-actieve acne</li>
                                <li>-acne littekens</li>
                                <li>-verstopte poriën</li>
                                <li>-littekens</li>
                                <li>-striae</li>
                                <li>-rokershuid</li>
                            </p>


                            <div className="two-column">
                                <div className="row">
                                    <div className="image-column col-xl-12 col-lg-12 col-md-12">
                                        <figure><img src="assets/img/blog/salon2.JPEG" alt="blog"/></figure>
                                    </div>
                                </div>
                            </div>
                            <h3> Behandeling Kosten</h3>
                            <h5 style={{backgroundColor: "rgba(238, 235, 231, 0.4)"}}>MICRODERMABRASIE  45
                                Minuten €60.00
                            </h5>

                        </div>
                    </div>
                </div>
                <aside className="sidebar services-sidebar">
                    <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                        <h3 className="h3-title">Een behandeling reserveren? </h3>
                        <h3 className="h3-title">Zo Makkelijk en eenvoudig via de link : </h3>
                        <div className="second-header-btn">
                            <a href="https://amani-beauty-skin.salonized.com/widget_bookings/new" className="btn">
                                <i className="fas fa-calendar-alt"></i>
                                Maak Een Afspraak
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    )
}

export default Microdermabrasie