import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../../HomeMain/Bredcrumb'


const AlgenPeeling = () => {
    return (
        <>
            <Bredcrumb
                title="Bio peel treatment (Algen Peeling)"
                subtitle="Algen Peeling" />

            <div className="container">
                <div className="row justify-content-center align-items-center mt-55" >
                    <div className="service-detail">
                        <div className="content-box">
                            <h2> WAT IS EEN BIO PEEL & HOE WERKT HET?
                            </h2>
                            <p>Een Bio Peel is een biologische kruidenpeeling waarbij een mix van gedroogde kruiden, zeewier, algen en/ of plant- en fruit extracten op de huid aangebracht wordt. Deze extracten zetten de huid aan tot regeneratie, waardoor de huid zichzelf van binnenuit gaat herstellen, met een zichtbaar resultaat. Iedere peeling heeft hierbij zijn eigen werking en behandelt specifieke indicaties.
                                Door middel van een massage worden de heilzame stoffen uit de kruidenmix optimaal door de huid opgenomen. Direct na de behandeling is de huid diep gereinigd en voelt de huid zeer glad en zacht aan. Dat de huid zo glad en zacht aanvoelt komt door het wegnemen van de bovenste lagen dode huidcellen. Ter bescherming wordt een zonnefactor aangebracht.
                                Basaalcellenlaag
                                De Bio Peel richt zich met name op de basaalcellenlaag. Hier liggen de stamcellen van de huid. Door de intensieve voeding van de basaalcellenlaag tijdens de Bio Peel worden de stamcellen voorzien van een bom met voedingstoffen. Dit resulteert in meer gezondere huidcellen, en dus ook in huidverbetering van binnenuit.</p>
                                <div className="two-column">
                                <div className="row">
                                    <div className="text-column col-xl-12 col-lg-12 col-md-12">
                                        <figure className="image"><img src="assets/img/blog/algenklant.jpeg"  /></figure>
                                    </div>
                                </div>
                            </div>
                            <h3>Waarom deze behandeling?</h3>
                            <p>Wat gebeurt er na de Bio Peel?
                                Na de behandeling kan de huid droog aanvoelen. De mate van droogheid is per persoon verschillend en afhankelijk van het huidtype. Tijdens deze fase worden dode huidcellen losgelaten en wordt de nieuwe, frisse huid zichtbaar. Een volle crème of een olie-serum houdt de huid soepel, gaat droogheid tegen en zorgt ervoor dat schilfertjes minder zichtbaar zijn.
                                Voor intensieve resultaten wordt aangeraden om meerdere Bio Peel behandelingen in kuurverband te ondergaan. Daarna volstaat een periodieke onderhoudsbehandeling om het resultaat te behouden.
                                Wat kan men bereiken met een Bio Peel?
                                Fijnere poriën
                                Verhelderen van sommige hyperpigmentatie Herstel bij (uitgebluste) puistjes
                                Contra-indicaties
                                Gladder huidoppervlak, gladstrijken van lijntjes Herstellen van een onrustige huid
                                Intens opfrissen van een doffe, vale huid.
                                • Virale infecties, de infectie kan zich verspreiden
                                • Kanker, ook wanneer de cliënt nog kankermedicatie inneemt
                                • Open wondjes
                                • Actinische keratose
                                • Bepaalde medicatie
                                • 14 dagen wachttijd na Botox injecties (de Bio Peel ontgift de Botox)</p>
                                <div className="two-column">
                                <div className="row">
                                    <div className="image-column col-xl-12 col-lg-12 col-md-12">
                                        <figure ><img src="assets/img/blog/algen 1.png" alt="blog" /></figure>
                                    </div>
                                </div>
                            </div>
                            <h3> Behandeling Kosten</h3>
                            <h5 style={{backgroundColor:"rgba(238, 235, 231, 0.4)"}}>Bio Peel behandeling  60 Minuten €85.00
                            </h5>

                        </div>
                    </div>
                </div>
                <aside className="sidebar services-sidebar">
                    <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                        <h3 className="h3-title">Een behandeling reserveren? </h3>
                        <h3 className="h3-title">Zo Makkelijk en eenvoudig via de link : </h3>
                        <div className="second-header-btn">
                            <a href="https://amani-beauty-skin.salonized.com/widget_bookings/new" className="btn">
                                <i className="fas fa-calendar-alt"></i>
                                Maak Een Afspraak
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    )
}

export default AlgenPeeling