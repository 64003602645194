import { Link } from "react-router-dom";
import React from "react";
import BGImg from "../../assets/img/bg/footer-bg.png";
import Img1 from "../../assets/img/logo/logo2nbg.png";
import Img2 from "../../assets/img/icon/ft-icon01.png";
import Footer from "../../assets/img/icon/ft-icon02.png";
import Footer1 from "../../assets/img/icon/ft-icon03.png";

const Main = () => {
  return (
    <>
      <footer
        className="footer-bg footer-p pt-90"
        style={{ backgroundColor: "#252525", backgroundImage: `url(${BGImg})` }}
      >
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title mb-40">
                    <img src={Img1} alt="logo" />
                  </div>
                  <div className="footer-link">
                    <p>Adres Gegevens:</p>
                    <p>
                      Schuttersbergplein 2
                    </p>
                    <p>                      6823 KN Arnhem
                    </p>
                    <p>
                      De openingstijden kunnen afwijken. We raden je altijd aan om van tevoren contact met ons op te nemen.
                    </p>
                    <div className="f-contact">
                      <ul>
                        <li>
                          <div className="contact-box">
                            <div className="icon">
                              <img src={Img2} alt="logo" />
                            </div>
                            <div className="text">
                              <strong>Maandag t/m Vrijdag:</strong>
                              <p>9:00am - 18:00</p>
                              <strong>Zaterdag </strong>
                              <p>Op Afspraak </p>
                              <strong>Zondag</strong>
                              <p>Gesloten</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Other Links</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/"> Home</Link>
                      </li>
                      <li>
                        <Link to="/about">Over Ons</Link>
                      </li>
                      <li>
                        <Link to="/service">Diensten</Link>
                      </li>
                      <li>
                        <Link to="/contact"> Contact</Link>
                      </li>
                      {/*<li>*/}
                      {/*  <Link to="/team">Our Team</Link>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  <Link to="/blog">Latest Blog</Link>*/}
                      {/*</li>*/}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Ons  Diensten</h2>
                  </div>
                  <div className="footer-link">
                    <ul>

                      <li className="has-sub"> <Link to="/service">Diensten</Link></li>
                        <li><Link to="/meso">Mesotherapie</Link></li>
                          <li>
                            <Link to="/dermapen">Microneedling(Dermapen)</Link>
                          </li>
                          <li>
                            <Link to="/algen">Bio peel treatment(Algen Peeling)</Link>
                          </li>
                          <li>
                            <Link to="/prx">Prx treatment</Link>
                          </li>
                          <li>
                            <Link to="/chemische">Chemische Peeling treatment</Link>
                          </li>
                          <li>
                            <Link to="/microderma">Microdermabrasie
                            </Link>
                          </li>
                          <li>
                            <Link to="/dipte">Dipte reiniging
                            </Link>
                          </li>
                          <li>
                            <Link to="/laser">Laser Therspie
                            </Link>
                          </li>
                          <li>
                            <Link to="/harsen">Haarsen
                            </Link>
                          </li>
                      <li>
                        <Link  to="/advice">Een Advies Nodig?
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Volg Ons  !</h2>
                  </div>
                  {/*<div className="footer-link">*/}
                  {/*  <div*/}
                  {/*    className="subricbe p-relative"*/}
                  {/*    data-animation="fadeInDown"*/}
                  {/*    data-delay=".4s"*/}
                  {/*  >*/}
                  {/*    <form*/}
                  {/*      action="news-mail.php"*/}
                  {/*      method="post"*/}
                  {/*      className="contact-form "*/}
                  {/*    >*/}
                  {/*      <input*/}
                  {/*        type="text"*/}
                  {/*        id="email2"*/}
                  {/*        name="email2"*/}
                  {/*        className="header-input"*/}
                  {/*        placeholder="Your Email..."*/}
                  {/*        required*/}
                  {/*      />*/}
                  {/*      <button className="btn header-btn">*/}
                  {/*        <i className="fas fa-location-arrow"></i>*/}
                  {/*      </button>*/}
                  {/*    </form>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
                <div className="footer-social mt-10">
                  <a href="https://www.instagram.com/amani_beauty_skin/?igshid=MjEwN2IyYWYwYw%3D%3D" title="Instagram">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="https://www.tiktok.com/@amani_beauty_skin?_t=8eVBSRXijRI&_r=1" title="Tiktok">
                    <img src="/assets/img/logo/tiktok-24.ico" />
                  </a>

                </div>
              </div>
            </div>
            <div className="row align-items-center copyright-wrap">
              <div className="col-lg-6">
                <div className="copy-text">
                  Copyright &copy; 2023 <Link to="#">Amani Beauty</Link>. All rights
                  reserved.
                </div>
              </div>
              <div className="col-lg-6">
                <div className="copay-contact">
                  <ul>
                    <li>
                      <div className="c-contact">
                        <div className="icon">
                          <img src={Footer} alt="img" />
                        </div>
                        <div className="text">
                          <span>info@amanibeauty.nl</span>
                          <h3> Ons Email:</h3>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="c-contact">
                        <div className="icon">
                          <img src={Footer1} alt="img" />
                        </div>
                        <div className="text">
                          <span>+31 6 35622872</span>
                          <h3>Bel Nu:</h3>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Main;
