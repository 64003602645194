import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../../HomeMain/Bredcrumb'

const Dermapen = () => {
    return (
        <>

            <Bredcrumb
                title="Microneedling(Dermapen)"
                subtitle="Dermapen Therapie" />

            <div className="container">
                <div className="row justify-content-center align-items-center mt-55" >
                            <div className="service-detail">
                                <div className="content-box">
                                    <h2> WAT IS MICRONEEDLING?
                                    </h2>
                                    <p>Microneedling, ook wel bekend onder de naam Mesotherapie, is een effectieve behandeling welke met name gebruikt wordt voor huidverbetering (anti-aging). “Meso” betekend mid-dermaal, en geeft aan in welk deel van de huid deze behandeling toegepast wordt.
                                        Microneedling is een behandeling waarbij er met een speciale Pen een groot aantal kleine kanaaltjes inde huid gemaakt worden. Tijdens de behandeling worden speciale actieve werkstoffen op de huid aangebracht, die via de kanaaltjes direct worden opgenomen door dieper gelegen huidlagen. De huid herstelt en sluit deze kanaaltjes met huideigen, glad collageen.
                                        Collageen
                                        Microneedling richt zich met name op collageenprocessen. Naarmate de huid ouder wordt maakt het lichaam minder collageen aan. De huid begint slapper te worden en er ontstaan rimpels. Door de Microneedling behandeling wordt het lichaam aangezet om de gemaakte kanaaltjes dicht te maken met collageen. Hierdoor wordt de huid steviger en worden rimpeltjes van binnenuit opgevuld. Het aangemaakte collageen blijft voor lange tijd aanwezig in de huid, alvorens het door het natuurlijke proces van het lichaam weer afgebroken wordt. Om het resultaat van de behandeling te behouden, wordt er 3 tot 4 keer par jaar een onderhoudsbehandeling toegepast.</p>
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="text-column col-xl-12 col-lg-12 col-md-12">
                                                <figure className="image"><img src="assets/img/blog/derma.JPEG"  /></figure>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Waarom deze behandeling?</h3>
                                    <p>Microneedling wordt tevens toegepast bij het verminderen van littekens. Littekens bestaan uit littekencollageen, en omdat dit ook een collageenproces is, kunnen we dit reguleren met Microneedling. Littekencollageen is, in tegenstelling tot normaal collageen, korrelig en bobbelig van structuur. We gebruiken Microneedling om het littekencollageen deels af te breken en de bloedcirculatie in dit gebied te optimaliseren.
                                        Grove poriën
                                        Het verfijnen van grove poriën is doorgaans erg lastig, omdat hier collageen voor nodig is. Tijdens de Microneedling behandeling wordt er ook collageen aangemaakt in de bovenste laag van de huid, waardoor grove poriën verfijnt en verstrakt worden.
                                        Lichaam
                                        Op het lichaam wordt Microneedling toegepast bij zwangerschapsstriemen, sinaasappelhuid en littekens op het lichaam.
                                        Hyperpigmentatie
                                        De behandeling richt zich ook op het verminderen van zonneschade, melasma of ouderdomspigment. Het reguleert de melanocyten en helpt bij het afbreken van melanine-clusters. Ook post-inflammatoire hyperpigmentatie, wat kan ontstaan na puistjes, kan met Microneedling minder zichtbaar worden.</p>
                                    <div className="two-column">
                                        <div className="row">
                                            <div className="image-column col-xl-12 col-lg-12 col-md-12">
                                                <figure ><img src="assets/img/blog/girl.JPEG" alt="blog" /></figure>
                                            </div>
                                        </div>
                                    </div>
                                    <h3> Behandeling Kosten</h3>
                                    <h5 style={{backgroundColor:"rgba(238, 235, 231, 0.4)"}}>Microneedling ( dermapen) 60 Minuten €120.00
                                    </h5>

                                </div>
                            </div>
                        </div>
                <aside className="sidebar services-sidebar">
                    <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                        <h3 className="h3-title">Een behandeling reserveren? </h3>
                        <h3 className="h3-title">Zo Makkelijk en eenvoudig via de link : </h3>
                        <div className="second-header-btn">
                            <a href="https://amani-beauty-skin.salonized.com/widget_bookings/new" className="btn">
                                <i className="fas fa-calendar-alt"></i>
                                Maak Een Afspraak
                            </a>
                        </div>
                    </div>
                </aside>
                    </div>


        </>
    )
}

export default Dermapen