import BGImg3 from "../../assets/img/bg/ap-bg.png";
import React from "react";
import Img from "../../assets/img/bg/open-box-img.png";
import {Link} from "react-router-dom";

const Appointment = () => {
  return (
    <>
      <section
        id="booking"
        className="booking-area p-relative pt-120 pb-120"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${BGImg3})`,
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="contact-bg">
                <div className="section-title center-align">
                  <h5>In Contact Komen</h5>
                  <h2>Maak Een Afsprak</h2>
                  <h2>Exclusief voor Dames</h2>
                  <p>
                    Heeft u vragen of opmerkingen of wilt u direct een afspraak maken?
                    Zo makkelijk via onderstaande links:                  </p>
                </div>
                <div className="second-header-btn row justify-content-center text-center">
                  <div className="col-md-4 mb-20">
                    <a href="https://amani-beauty-skin.salonized.com/widget_bookings/new" className="btn btn-primary">
                      <i className="fas fa-calendar-alt"></i> Afspraak
                    </a>
                  </div>
                  <div className="col-md-4 mb-20">
                    <a href="mailto:info@amanibeauty.nl" className="btn btn-primary">
                      <i className="fas fa-envelope"></i> E-Mail
                    </a>
                  </div>
                  <div className="col-md-4 mb-20">
                    <a href="tel:+31 6 35622872" className="btn btn-primary">
                      <i className="fas fa-phone"></i> Bel Ons
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a href="https://wa.me/31635622872" target="_blank" className="btn btn-success">
                      <i className="fab fa-whatsapp"></i> WhatsApp
                    </a>
                  </div>
                </div>

                {/*<form*/}
                {/*  action="mail.php"*/}
                {/*  method="post"*/}
                {/*  className="contact-form mt-30"*/}
                {/*>*/}
                {/*  <div className="row">*/}
                {/*    <div className="col-lg-6">*/}
                {/*      <div className="contact-field p-relative c-name mb-20">*/}
                {/*        <input*/}
                {/*          type="text"*/}
                {/*          id="firstn"*/}
                {/*          name="firstn"*/}
                {/*          placeholder="First Name"*/}
                {/*          required*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*    </div>*/}

                {/*    <div className="col-lg-6">*/}
                {/*      <div className="contact-field p-relative c-subject mb-20">*/}
                {/*        <input*/}
                {/*          type="text"*/}
                {/*          id="email"*/}
                {/*          name="email"*/}
                {/*          placeholder="Eamil"*/}
                {/*          required*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-6">*/}
                {/*      <div className="contact-field p-relative c-subject mb-20">*/}
                {/*        <select*/}
                {/*          className="custom-select"*/}
                {/*          id="inputGroupSelect04"*/}
                {/*          aria-label="Example select with button addon"*/}
                {/*        >*/}
                {/*          <option> Department...</option>*/}
                {/*          <option value="1">One</option>*/}
                {/*          <option value="2">Two</option>*/}
                {/*          <option value="3">Three</option>*/}
                {/*        </select>*/}
                {/*        <i className="fa fa-angle-down"></i>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-6">*/}
                {/*      <div className="contact-field p-relative c-subject mb-20">*/}
                {/*        <input*/}
                {/*          type="date"*/}
                {/*          id="subject"*/}
                {/*          name="subject"*/}
                {/*          placeholder="Subject"*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    <div className="col-lg-12">*/}
                {/*      <div className="contact-field p-relative c-message mb-30">*/}
                {/*        <textarea*/}
                {/*          name="message"*/}
                {/*          id="message"*/}
                {/*          cols="30"*/}
                {/*          rows="10"*/}
                {/*          placeholder="Write comments"*/}
                {/*        ></textarea>*/}
                {/*      </div>*/}
                {/*      <div className="slider-btn">*/}
                {/*        <button*/}
                {/*          className="btn ss-btn"*/}
                {/*          data-animation="fadeInRight"*/}
                {/*          data-delay=".8s"*/}
                {/*        >*/}
                {/*          <span>Submit Now</span>*/}
                {/*        </button>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</form>*/}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="opening-time">
                <div className="open-img">
                  <img src={Img} alt="icon01" />
                </div>
                <h3>Openingstijden</h3>
                <div className="text">
                  <div className="left-text">Maandag t/m Vrijdag:</div>
                  <div className="right-text">09:00 am – 18:00 pm</div>
                </div>
                <div className="text">
                  <div className="left-text">Zaterdag:</div>
                  <div className="right-text">Op Afspraak </div>
                </div>
                <div className="text">
                  <div className="left-text">Zondag:</div>
                  <div className="right-text">Gesloten </div>
                </div>
                <div className="discount">
                  De openingstijden kunnen afwijken. We raden je altijd aan om van tevoren contact met ons op te nemen.</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Appointment;
