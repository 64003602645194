import React from "react";
import Appointment from "../HomeMain/Appointment";
import { Link } from "react-router-dom";
import Bredcrumb from "../HomeMain/Bredcrumb";

const Main = () => {
  return (
    <>
      <Bredcrumb title="Contact Us" subtitle="Contact Us" />
      <Appointment />

      <div className="map fix" style={{ background: "#F5F5F5" }}>
        <div className="container-flud">
          <div className="row">
            <div className="col-lg-12">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d38937.75582240824!2d5.914016245071144!3d51.9868795746195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7a4f391c3eb5d%3A0x65d73043746655c5!2sSchuttersbergplein%202%2C%206823%20KN%20Arnhem%2C%20Netherlands!5e0!3m2!1sen!2sus!4v1618567685329!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    style={{ border: "0" }}
                    loading="lazy"
                ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
