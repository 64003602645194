import {Link} from "react-router-dom";
import React, {useState} from "react";
import Slider from "react-slick";
import BGImg from "../../assets/img/slider/personen.JPEG";
import BGImg1 from "../../assets/img/slider/salon.JPEG";
import About from "../HomeMain/About";
import Appointment from "../HomeMain/Appointment";
import Blog from "../HomeMain/Blog";
import Testimonial from "../HomeMain/Testimonial";
import Service from "../HomeMain/Service";
import Gallery from "../HomeMain/Gallery";
import Pricing from "../HomeMain/Pricing";
import Feature from "../HomeMain/Feature";
import Product from "../HomeMain/Product";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import Img1 from "../../assets/img/logo/nlogo.png";

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <button
            type="button"
            className={className}
            style={{...style}}
            onClick={onClick}
        >
            {" "}
            <FontAwesomeIcon icon={faAngleLeft}/>
        </button>
    );
}

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <button
            type="button"
            className={className}
            style={{...style}}
            onClick={onClick}
        >
            {" "}
            <FontAwesomeIcon icon={faAngleRight}/>
        </button>
    );
}

const Main = () => {
    const [ytshow, setytshow] = useState();

    const home = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 900,
        autoplay: true,
        slidesToShow: 1,
        prevArrow: <SamplePrevArrow/>,
        nextArrow: <SampleNextArrow/>,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <main style={{backgroundColor:'#FFFCF8'}}>
                <section  className="slider-area fix p-relative">
                    <Slider className="slider-active" {...home}>
                        <div className="col-xl-2">
                            <div
                                className="single-slider slider-bg d-flex align-items-center"
                                style={{backgroundImage: `url(${BGImg})`}}
                            >
                                <div className="container">
                                    <div className="row justify-content-center align-items-center" >
                                        <div className="col-lg-6 col-md-7" >
                                            <div className="slider-content s-slider-content mt-60" style={{backgroundColor: 'rgba(238, 235, 231, 0.4)'}} >
                                                {/*<h5 data-animation="fadeInUp" data-delay=".4s" >*/}
                                                {/*    Amani Beauty {" "}*/}
                                                {/*</h5>*/}
                                                <h2 data-animation="fadeInUp" data-delay=".4s" >
                                                    Laat Je Huid
                                                    <br/>
                                                    Pracht & Stralend
                                                </h2>
                                                {/*<p data-animation="fadeInUp" data-delay=".6s" >*/}
                                                {/*    Donec vitae libero non enim placerat eleifend aliquam*/}
                                                {/*    erat volutpat. Curabitur diam ex, dapibus purus*/}
                                                {/*    sapien, cursus sed nisl tristique.*/}
                                                {/*</p>*/}
                                                <div className="slider-btn mt-20 pb-30 pl-40 pt-30 mt-10" style={{ marginLeft: "-33px" }}>
                                                    <Link
                                                        to="/service"
                                                        className="btn ss-btn mr-15 m"
                                                        data-animation="fadeInUp"
                                                        data-delay=".8s"
                                                    >
                                                        Meer Ontdeken
                                                    </Link>
                                                    <a
                                                        href="https://amani-beauty-skin.salonized.com/widget_bookings/new"
                                                        className="btn ss-btn mr-15"
                                                        data-animation="fadeInUp"
                                                        data-delay=".8s"
                                                    >
                                                        Afspraak voor dames
                                                    </a>
                                                    {/*<Link*/}
                                                    {/*  to="#"*/}
                                                    {/*  onClick={() => setytshow(true)}*/}
                                                    {/*  className="video-i popup-video"*/}
                                                    {/*  data-animation="fadeInUp"*/}
                                                    {/*  data-delay=".8s"*/}
                                                    {/*>*/}
                                                    {/*  <i className="fas fa-play"></i> Our Intro Video!*/}
                                                    {/*</Link>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-5 p-relative"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div
                                className="single-slider slider-bg d-flex align-items-center"
                                style={{backgroundImage: `url(${BGImg1})`}}
                            >
                                <div className="container">
                                    <div className="row justify-content-center align-items-center" >
                                        <div className="col-lg-6 col-md-7" >
                                            <div className="slider-content s-slider-content mt-60" style={{backgroundColor: 'rgba(238, 235, 231, 0.4)'}} >
                                                {/*<h5 data-animation="fadeInUp" data-delay=".4s" >*/}
                                                {/*    Amani Beauty {" "}*/}
                                                {/*</h5>*/}
                                                <h2 data-animation="fadeInUp" data-delay=".4s" >
                                                    Laat Je Huid
                                                    <br/>
                                                    Pracht & Stralend
                                                </h2>
                                                {/*<p data-animation="fadeInUp" data-delay=".6s" >*/}
                                                {/*    Donec vitae libero non enim placerat eleifend aliquam*/}
                                                {/*    erat volutpat. Curabitur diam ex, dapibus purus*/}
                                                {/*    sapien, cursus sed nisl tristique.*/}
                                                {/*</p>*/}
                                                <div className="slider-btn mt-20 pb-30 pl-40 pt-30 mt-10"style={{ marginLeft: "-33px" }}>
                                                    <Link
                                                        to="/service"
                                                        className="btn ss-btn mr-15"
                                                        data-animation="fadeInUp"
                                                        data-delay=".8s"
                                                    >
                                                        Meer Ontdeken
                                                    </Link>
                                                    <Link
                                                        to="/contact"
                                                        className="btn ss-btn mr-15"
                                                        data-animation="fadeInUp"
                                                        data-delay=".8s"
                                                    >
                                                        Afspraak voor dames
                                                    </Link>
                                                    {/*<Link*/}
                                                    {/*  to="#"*/}
                                                    {/*  onClick={() => setytshow(true)}*/}
                                                    {/*  className="video-i popup-video"*/}
                                                    {/*  data-animation="fadeInUp"*/}
                                                    {/*  data-delay=".8s"*/}
                                                    {/*>*/}
                                                    {/*  <i className="fas fa-play"></i> Our Intro Video!*/}
                                                    {/*</Link>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-5 p-relative"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </section>


                <br/>
                <Feature/>
                <About/>
                {/*<div className="home-service">*/}
                {/*    <Service/>*/}
                {/*</div>*/}
                <Appointment/>
                {/*<Product />*/}
                {/*<Pricing />*/}
                {/*<Gallery />*/}
                <Testimonial />
                {/*<Blog />*/}
            </main>
            {ytshow && (
                <>
                    <div className="mfp-bg mfp-ready"></div>
                    <div
                        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
                        tabindex="-1"
                        style={{overflow: "hidden"}}
                    >
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler">
                                    <button
                                        title="Close (Esc)"
                                        type="button"
                                        className="mfp-close"
                                        onClick={() => setytshow(false)}
                                    >
                                        ×
                                    </button>
                                    <iframe
                                        className="mfp-iframe"
                                        src="//www.youtube.com/embed/vKSA_idPZkc?autoPlay=1"
                                        frameborder="0"
                                        allowfullscreen=""
                                    ></iframe>
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Main;
