import React from "react";
import BGImg1 from "../../assets/img/bg/about-bg.png";
import { Link } from "react-router-dom";
import Img from "../../assets/img/features/about.JPEG";

const About = () => {
  return (
    <>
      <section
        className="about-area about-p pt-120 pb-120 p-relative fix"
        style={{
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundImage: `url(${BGImg1})`,
        }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>Over Ons</h5>
                  <h2> Beauty is meer  dan een passie</h2>
                </div>
                <img src={Img} alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content  wow fadeInRight  animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                {/*<div className="about-title second-title pb-25">*/}
                {/*  <h5>About Us</h5>*/}
                {/*  <h2>Best Way To Care Your Skin</h2>*/}
                {/*</div>*/}
                <p>
                  Bij onze salon is schoonheid en huidverzorging niet alleen een passie; het is onze manier van leven. Onze reis begon in Syrië, waar ik mijn liefde voor schoonheid volgde door verschillende trainingen in cosmetica bij te wonen. Toen we naar Libanon verhuisden, ging ik door met mijn training in een van de mooiste salons, waar mijn interesse in huidverzorging verder verdiepte.
</p>
                 <p> Toen we ons in Nederland vestigden, wist ik dat ik me nog meer wilde verdiepen in huidverzorging en me wilde richten op huidbehandelingen. Ik nam het besluit om te studeren en een allround schoonheidsspecialiste niveau 4 te worden. Tijdens mijn studie zocht ik actief naar aanvullende cursussen en behaalde ik verschillende certificaten in verschillende huid verzorging en huidverbetring.
</p>
                  Terwijl ik mijn opleiding voortzette bij ROC Nijmegen, schreef ik me tegelijkertijd in bij de Kamer van Koophandel en begon ik klanten te verwelkomen. Gaandeweg ontwikkelde ik expertise in het behandelen van acne, pigmentatie, littekens en meer.

               <p>   Mijn passie voor huidverzorging leidde me naar medische producten die diepere huidlagen aanspreken en verschillende huidproblemen effectief oplossen.

                  Toch is er altijd tijd om te genieten van ontspanning en zelfzorg. Mijn klanten zijn dol op onze massages, waarbij we medische, therapeutische en klassieke technieken combineren voor zowel genezing als ontspanning.

                  Bij Schoonheidssalon Beauty Skin zijn we toegewijd om u topbehandelingen, gepersonaliseerde huidverzorgingsoplossingen en een verjongende ervaring te bieden, zodat u zich vertroeteld en verfrist voelt.

                  Kom bij ons en ontdek de schoonheid in uzelf, en laat ons voor uw huid zorgen zoals nooit tevoren!
                </p>
                <div className="slider-btn mt-20">
                  <Link to="/service" className="btn ss-btn smoth-scroll">
                    Explore More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
