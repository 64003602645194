import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../../HomeMain/Bredcrumb'


const MesoTherapie = () => {
    return (
        <>
            <Bredcrumb
                title="MesoTherapie"
                subtitle="Haar/Gezicht" />

            <div className="container">
                <div className="row justify-content-center align-items-center mt-55" >
                    <div className="service-detail">
                        <div className="content-box">
                            <h2> WAT IS HAAR MESOTHERAPIE?
                            </h2>
                            <p>Mesotherapie kan helpen bij het verminderen van haaruitval van verschillende oorsprong waaronder alopecia bij zowel mannen als vrouwen. Binnen het assortiment van Toskani zijn er verschillende producten te vinden die specifiek werken op de hoofdhuid. De cocktails bevatten hoge concentraties aan werkzame bestanddelen om de bloedcirculatie van de hoofdhuid te bevorderen, haargroei te stimuleren en haarfollikels te versterken. Dit komt tot stand door de aanvoer van voedingsstoffen uit de mesococktails te bevorderen. Hierdoor zal haaruitval afnemen en bestaand haar worden versterkt. Het werkt in op de verschillende fases van de haarcyclus</p>
                            <div className="two-column">
                                <div className="row">
                                    <div className="text-column col-xl-12 col-lg-12 col-md-12">
                                        <figure className="image"><img src="assets/img/blog/mesohaar.jpg"  /></figure>
                                    </div>
                                </div>
                            </div>
                            <h3> Behandeling Kosten</h3>
                            <h5 style={{backgroundColor:"rgba(238, 235, 231, 0.4)"}}>Haar MesoTherapie 30 Minuten € 110,00
                            </h5>

                        </div>
                    </div>
                </div>
                <aside className="sidebar services-sidebar">
                    <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                        <h3 className="h3-title">Een behandeling reserveren? </h3>
                        <h3 className="h3-title">Zo Makkelijk en eenvoudig via de link : </h3>
                        <div className="second-header-btn">
                            <a href="https://amani-beauty-skin.salonized.com/widget_bookings/new" className="btn">
                                <i className="fas fa-calendar-alt"></i>
                                Maak Een Afspraak
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    )
}

export default MesoTherapie