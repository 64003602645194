import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../../HomeMain/Bredcrumb'

const Chemische = () => {
    return (
        <>

            <Bredcrumb
                title="Chemische peeling treatment"
                subtitle="Chemische peeling" />

            <div className="container">
                <div className="row justify-content-center align-items-center mt-55" >
                    <div className="service-detail">
                        <div className="content-box">
                            <h2>  WAT ZIJN CHEMISCHE PEELINGS?
                            </h2>
                            <p>
                                Een chemische peeling is een dermocosmetische behandeling die de huid op zowel het gezicht als het lichaam helpt te verbeteren. Mot de toepassing van een hoge concentratie van één of meerdere zuren, zijn peelings gebaseerd op het stimuleren van rogeneratie op een versnelde en gecontroleerde manier. Hierdoor wordt de huid vervangen voor een reeuwe, gezonde huid

                            </p> <div className="two-column">
                                <div className="row">
                                    <div className="text-column col-xl-12 col-lg-12 col-md-12">
                                        <figure className="image"><img src="assets/img/blog/chemosvh.JPEG"  /></figure>
                                    </div>
                                </div>
                            </div>
                            <h3>Waarom deze behandeling?</h3>
                            <p>
                                De afbladderende werking van de huid draagt bij tot de eliminatie van de buitenste huidagen en stimuleert de productie van collageen, elastine en glycoaminoglycanen (GAG's) Chemische peelings kunnen ook worden gebruikt voor andere doeleinden zoals het zuiveren en voorzien van zuurstof van de huid, het verkleinen van vergrote porien het verminderen van rimpels, het geven van helderheid, het vervagen van imperfecties en het verminderen van pigmentvlekken en (acne) littekens
                            </p>
                            <div className="two-column">
                                <div className="row">
                                    <div className="image-column col-xl-12 col-lg-12 col-md-12">
                                        <figure ><img src="assets/img/blog/front2.JPEG" alt="blog" /></figure>
                                    </div>
                                </div>
                            </div>
                            <h3> Behandeling Kosten</h3>
                            <h5 style={{backgroundColor:"rgba(238, 235, 231, 0.4)"}}>Chemische Peeling 45 Minuten €85.00
                            </h5>

                        </div>
                    </div>
                </div>
                <aside className="sidebar services-sidebar">
                    <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                        <h3 className="h3-title">Een behandeling reserveren? </h3>
                        <h3 className="h3-title">Zo Makkelijk en eenvoudig via de link : </h3>
                        <div className="second-header-btn">
                            <a href="https://amani-beauty-skin.salonized.com/widget_bookings/new" className="btn">
                                <i className="fas fa-calendar-alt"></i>
                                Maak Een Afspraak
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    )
}

export default Chemische