import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../../HomeMain/Bredcrumb'


const Advice = () => {
    return (
        <>
            <Bredcrumb
                title="Een Advies Nodig? "
                subtitle="Een Advies Nodig?" />

            <div className="container">
                <div className="row justify-content-center align-items-center mt-55" >
                    <div className="service-detail">
                        <div className="content-box">
                            <h2> STEL JOUW VRAAG AAN AMANI.
                            </h2>
                            <p>Ben je onzeker over welke behandeling jouw huid nodig heeft? Laat het aan ons over om je te adviseren en een behandeling op maat voor je te creëren, gebaseerd op het deskundige advies van onze ERVARING .

                                Met onze jarenlange ervaring en passie voor huidverzorging, staan we klaar om al jouw vragen te beantwoorden en jou te voorzien van persoonlijk advies. Of het nu gaat om het verminderen van acne, het behandelen van pigmentvlekken, of het bestrijden van littekens, wij hebben de kennis en expertise om de juiste oplossing voor jouw huid te vinden.

                                Aarzel niet en stel jouw vraag aan Amani. Wij kijken er naar uit om je te helpen stralen met een stralende en gezonde huid!</p>
                                <div className="two-column">
                            <div className="row">
                                    <div className="text-column col-xl-12 col-lg-12 col-md-12">
                                        <figure className="image"><img src="assets/img/blog/algenklant.JPEG"  /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <aside className="sidebar services-sidebar">
                    <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                        <h3 className="h3-title">Een Advies Vragen? </h3>
                        <h3 className="h3-title">Contact opnemen : </h3>
                        <div className="second-header-btn mt-30 mb-35">
                            <a href="tel:+31 6 35622872" className="btn btn-primary">
                                <i className="fas fa-phone"></i> Bel Ons
                            </a>
                        </div>
                        <div className="second-header-btn">
                            <a href="https://wa.me/31635622872" target="_blank" className="btn btn-success">
                                <i className="fab fa-whatsapp"></i> WhatsApp
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    )
}

export default Advice