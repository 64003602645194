import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../../HomeMain/Bredcrumb'


const Laser = () => {
    return (
        <>
            <Bredcrumb
                title="Laser Therapie "
                subtitle="Laser Therapie" />

            <div className="container">
                <div className="row justify-content-center align-items-center mt-55" >
                    <div className="service-detail">
                        <div className="content-box">
                            <h2> WAT IS LASER BEHANDELING?
                            </h2>
                            <p>Laserontharing is een zeer doeltreffende methode om definitief afscheid te nemen van roodheid, bultjes ingegroeide haartjes en vervelende ontstekingen door harsen en scheren.
                            </p>
                                <div className="two-column">
                            <div className="row">
                                    <div className="text-column col-xl-12 col-lg-12 col-md-12">
                                        <figure className="image"><img src="assets/img/blog/laser.jpg"  /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <aside className="sidebar services-sidebar">
                    <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                        <h3 className="h3-title">Een behandeling reserveren? </h3>
                        <h3 className="h3-title">Contact opnemen : </h3>
                        <div className="second-header-btn mt-30 mb-35">
                            <a href="tel:+31 6 35622872" className="btn btn-primary">
                                <i className="fas fa-phone"></i> Bel Ons
                            </a>
                        </div>
                        <div className="second-header-btn">
                            <a href="https://wa.me/31635622872" target="_blank" className="btn btn-success">
                                <i className="fab fa-whatsapp"></i> WhatsApp
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    )
}

export default Laser