import { Link } from 'react-router-dom'
import React from 'react'
import Icon1 from '../../assets/img/features/haarmeso.jpg'
import Icon2 from '../../assets/img/features/dermapen.jpg'
import Icon3 from '../../assets/img/features/algenpeeling.jpeg'
import Icon4 from '../../assets/img/features/haarsen.jpg'
import Icon5 from '../../assets/img/features/laser .jpg'
import Icon6 from '../../assets/img/features/diptereinigen.jpg'
import Icon7 from '../../assets/img/features/chemische peeling.jpg'
import Icon8 from '../../assets/img/features/Microdermabrasie.jpg'
import Icon9 from '../../assets/img/features/prx.jpg'
import Icon10 from '../../assets/img/features/advice.jpg'








import Img1 from "../../assets/img/logo/nlogo.png";

const Feature = () => {
    return (
        <>
            {/*//here class feature-area that change the BG has been deleted from section :*/}
            <section className=" p-relative fix" style={{backgroundColor:'#FFFCF8'}}>
                <div  style={{textAlign: 'center',backgroundColor:'#FFFCF8'}}>
                    <img src={Img1} alt="logo"/>
                    <br/>
                    <br/>
                    <h2 data-animation="fadeInUp" data-delay=".4s">

                    Ontdek Ons Diensten </h2>
                </div>
                <div className="containe-fluid" >
                    <div className="row pt-30">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="feature-box">
                                <div className="text bg-transparent">
                                    <Link to="/meso">Mesotherapie</Link>
                                </div>
                                <img src={Icon1} alt="icon01" />
                            </div>
                        </div>
                        <br/>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="feature-box">
                                <div className="text">
                                    {/*<h5>Offer For Man</h5>*/}
                                    {/*<h3>Get The Best Glow With Our Man Products</h3>*/}
                                    <Link to="/dermapen">Microneedling(Dermapen)
                                    </Link>
                                </div>
                                <img src={Icon2} alt="icon01" />
                            </div>
                        </div>
                        <br/>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="feature-box">
                                <div className="text">
                                    {/*<h5>Offer For Women</h5>*/}
                                    {/*<h3>Get The Best Care For Couples</h3>*/}
                                    <Link to="/algen">Bio peel treatment(Algen Peeling)
                                    </Link>
                                </div>
                                <img src={Icon3} alt="icon01" />
                            </div>
                        </div>
                        <br/>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="feature-box">
                                <div className="text">
                                    <Link to="/harsen">Harsen</Link>
                                </div>
                                <img src={Icon4} alt="icon01" />
                            </div>
                        </div>
                        <br/>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="feature-box">
                                <div className="text">
                                    {/*<h5>Offer For Man</h5>*/}
                                    {/*<h3>Get The Best Glow With Our Man Products</h3>*/}
                                    <Link to="/laser">Laser Therapie</Link>
                                </div>
                                <img src={Icon5} alt="icon01" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="feature-box">
                                <div className="text">
                                    {/*<h5>Offer For Women</h5>*/}
                                    {/*<h3>Get The Best Care For Couples</h3>*/}
                                    <Link to="/dipte">Dipte reiniging
                                    </Link>
                                </div>
                                <img src={Icon6} alt="icon01" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="feature-box">
                                <div className="text">
                                    {/*<h5>Offer For Women</h5>*/}
                                    {/*<h3>Get The Best Care For Couples</h3>*/}
                                    <Link to="/chemische">Chemische Peeling
                                    </Link>
                                </div>
                                <img src={Icon7} alt="icon01" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="feature-box">
                                <div className="text">
                                    {/*<h5>Offer For Women</h5>*/}
                                    {/*<h3>Get The Best Care For Couples</h3>*/}
                                    <Link to="/microderma">Microdermabrasie
                                    </Link>
                                </div>
                                <img src={Icon8} alt="icon01" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="feature-box">
                                <div className="text">
                                    {/*<h5>Offer For Women</h5>*/}
                                    {/*<h3>Get The Best Care For Couples</h3>*/}
                                    <Link to="/prx">PRX Behandeling
                                    </Link>
                                </div>
                                <img src={Icon9} alt="icon01" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="feature-box">
                                <div className="text">
                                    {/*<h5>Offer For Women</h5>*/}
                                    {/*<h3>Get The Best Care For Couples</h3>*/}
                                    <Link to="/advice">Een Advies nodig?
                                    </Link>
                                </div>
                                <img src={Icon10} alt="icon01" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Feature