import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../../HomeMain/Bredcrumb'


const Dipte = () => {
    return (
        <>
            <Bredcrumb
                title="Dipte reiniging"
                subtitle="Dipte reiniging " />

            <div className="container">
                <div className="row justify-content-center align-items-center mt-55" >
                    <div className="service-detail">
                        <div className="content-box">
                            <h2> WAT IS DIPTEREINIGING?
                            </h2>
                            <p>

                                Een dieptereiniging zorgt ervoor dat de aanwezige onzuiverheden zoals puistjes, mee-eters en gerstekorrels handmatig worden geledigd.
                            </p>
                            <div className="two-column">
                                <div className="row">
                                    <div className="text-column col-xl-12 col-lg-12 col-md-12">
                                        <figure className="image"><img src="assets/img/blog/chem.jpg"  /></figure>
                                    </div>
                                </div>
                            </div>

                            <h3> Behandeling Kosten</h3>
                            <h5 style={{backgroundColor:"rgba(238, 235, 231, 0.4)"}}> DipteReiniging 45 Minuten €50.00
                            </h5>

                        </div>
                    </div>
                </div>
                <aside className="sidebar services-sidebar">
                    <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                        <h3 className="h3-title">Een behandeling reserveren? </h3>
                        <h3 className="h3-title">Zo Makkelijk en eenvoudig via de link : </h3>
                        <div className="second-header-btn">
                            <a href="https://amani-beauty-skin.salonized.com/widget_bookings/new" className="btn">
                                <i className="fas fa-calendar-alt"></i>
                                Maak Een Afspraak
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    )
}

export default Dipte