import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import Img1 from "../../assets/img/logo/logol21.png";


const Main = () => {
  const [mobile, setmobile] = useState(false);
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
//code to make the navbar sticky with bg white
  const navbarStyles = {
    backgroundColor: 'rgba(238, 235, 231, 0.8)', // Set the desired transparency here (adjust the alpha value)
  };
    // useEffect(() => {
    //
    //   const handleScroll = () => {
    //     const header = document.getElementById('header-sticky');
    //     if (window.scrollY > 0) {
    //       header.style.backgroundColor = 'rgba(238, 235, 231, 0.8)';
    //     } else {
    //       header.style.backgroundColor = '';
    //     }
    //   };
    //
    //   window.addEventListener('scroll', handleScroll);
    //
    //   return () => {
    //     window.removeEventListener('scroll', handleScroll);
    //   };
    // }, []);

  return (
    <>
      <header className="header-area header-three">
        <div id="header-sticky" className="menu-area" style={{position: 'fixed', top: 0, width: '100%' ,backgroundColor: 'rgba(238, 235, 231, 0.8)'}}>
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2">
                  <div >
                    <Link to="/">
                      <img src={Img1} alt="logo" style={{marginBottom:'-10px',marginTop:'-10px'}}/>
                    </Link>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7">
                  <div className="main-menu text-right text-xl-right">
                    <nav id="mobile-menu">
                      <ul >
                        <li className="has-sub">
                          <Link to="/">Home</Link>
                          {/*<ul>*/}
                          {/*  <li>*/}
                          {/*    <Link to="/">Home Page 01</Link>*/}
                          {/*  </li>*/}
                          {/*  <li>*/}
                          {/*    <Link to="/home-two">Home Page 02</Link>*/}
                          {/*  </li>*/}
                          {/*  /!*<li>*!/*/}
                          {/*  /!*  <Link to="/home-three">Home Page 03</Link>*!/*/}
                          {/*  /!*</li>*!/*/}
                          {/*</ul>*/}
                        </li>
                        <li className="has-sub">
                          <Link to="/about">Over Ons</Link>
                        </li>
                        <li className="has-sub">
                          <Link to="/service">Diensten</Link>
                          <ul>
                            <li>
                              <Link to="/meso">Mesotherapie</Link>
                            </li>
                            <li>
                              <Link to="/dermapen">Microneedling(Dermapen)</Link>
                            </li>
                            <li>
                              <Link to="/algen">Bio peel treatment(Algen Peeling)</Link>
                            </li>
                            <li>
                              <Link to="/prx">Prx treatment</Link>
                            </li>
                            <li>
                              <Link to="/chemische">Chemische Peeling treatment</Link>
                            </li>
                            <li>
                              <Link to="/microderma">Microdermabrasie
                              </Link>
                            </li>
                            <li>
                              <Link to="/dipte">Dipte reiniging
                              </Link>
                            </li>
                            <li>
                              <Link to="/laser">Laser Therspie
                              </Link>
                            </li>
                            <li>
                              <Link to="/harsen">Haarsen
                              </Link>
                            </li>
                            <li>
                              <Link  to="/advice">Een Advies Nodig ?
                              </Link>
                            </li>
                          </ul>
                        </li>
                        {/*<li className="has-sub">*/}
                        {/*  <Link to="#">Pages</Link>*/}
                        {/*  <ul>*/}
                        {/*    <li>*/}
                        {/*      <Link to="/gallery">Gallery</Link>*/}
                        {/*    </li>*/}

                        {/*    <li>*/}
                        {/*      <Link to="/pricing">Pricing</Link>*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*      <Link to="/faq">Faq</Link>*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*      <Link to="/team">Team</Link>*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*      <Link to="/team-details">Team Details</Link>*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*      <Link to="/shop">Shop</Link>*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*      <Link to="/shop-details">Shop Details</Link>*/}
                        {/*    </li>*/}
                        {/*  </ul>*/}
                        {/*</li>*/}
                        {/*<li className="has-sub">*/}
                        {/*  <Link to="/blog">Blog</Link>*/}
                        {/*  <ul>*/}
                        {/*    <li>*/}
                        {/*      <Link to="/blog">Blog</Link>*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*      <Link to="/blog-details">Blog Details</Link>*/}
                        {/*    </li>*/}
                        {/*  </ul>*/}
                        {/*</li>*/}
                        <li className="has-sub">
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block mt-25 mb-25 text-right text-xl-right">
                  <div className="login">
                    <ul>
                      <li>
                        <div className="second-header-btn">
                          <a href="https://amani-beauty-skin.salonized.com/widget_bookings/new" className="btn">
                            Maak Een Afspraak
                            <p style={{fontSize:"0.75em"}}>Exclusief voor Dames</p>
                          </a>
                          
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                      <Link
                        to="#nav"
                        className={`meanmenu-reveal ${mobile && "meanclose"}`}
                        onClick={() =>
                          setmobile(mobile === true ? false : true)
                        }
                        style={{
                          right: "0",
                          left: "auto",
                          textAlign: "center",
                          textIndent: "0px",
                          fontSize: "18px",
                        }}
                      >
                        {mobile ? (
                          "X"
                        ) : (
                          <>
                            <span>
                              <span>
                                <span></span>
                              </span>
                            </span>
                          </>
                        )}
                      </Link>
                      <nav className="mean-nav">
                        {mobile && (
                          <ul style={{ display: mobile ? "block" : "none" }}>
                            <li className="has-sub">
                              <Link to="/">Home</Link>
                              {/*<ul style={{ display: show ? "block" : "none" }}>*/}
                              {/*  <li>*/}
                              {/*    <Link to="/">Home Page 01</Link>*/}
                              {/*  </li>*/}
                              {/*  <li>*/}
                              {/*    <Link to="/home-two">Home Page 02</Link>*/}
                              {/*  </li>*/}
                              {/*  <li>*/}
                              {/*    <Link to="/home-three">Home Page 03</Link>*/}
                              {/*  </li>*/}
                              {/*</ul>*/}
                              <Link
                                to="/"
                                className={`mean-expand ${
                                  show && "mean-clicked"
                                }`}
                                onClick={() =>
                                  setshow(show === true ? false : true)
                                }
                                style={{ fontSize: "18px" }}
                              >
                              </Link>
                            </li>
                            <li>
                              <Link to="/about/">Over Ons</Link>
                            </li>

                            <li className="has-sub">
                              <Link to="/service">Diensten</Link>
                              <ul style={{ display: show1 ? "block" : "none" }}>
                                <li>
                                  <Link to="/meso">Mesotherapie</Link>
                                </li>
                                <li>
                                  <Link to="/dermapen">Microneedling(Dermapen)
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/algen">Bio peel treatment(Algen Peeling)
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/prx">Prx treatment</Link>
                                </li>
                                <li>
                                  <Link to="/chemische">Chemische Peeling treatment</Link>
                                </li>
                                <li>
                                  <Link to="/microderma">Microdermabrasie
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/dipte">Dipte reiniging
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/laser">Laser Therspie
                                  </Link>
                                </li>
                                <li>
                                  <Link  to="/harsen">Haarsen
                                  </Link>
                                </li>
                                <li>
                                  <Link  to="/advice">Een Advies nodig ?
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/service">Ons Diensten</Link>
                                </li>
                              </ul>
                              <Link
                                to="#"
                                className={`mean-expand ${
                                  show1 && "mean-clicked"
                                }`}
                                onClick={() =>
                                  setshow1(show1 === true ? false : true)
                                }
                                style={{ fontSize: "18px" }}
                              >
                                +
                              </Link>
                            </li>
                            {/*<li className="has-sub">*/}
                            {/*  <Link to="#">Pages</Link>*/}
                            {/*  <ul style={{ display: show2 ? "block" : "none" }}>*/}
                            {/*    <li>*/}
                            {/*      <Link to="/gallery">Gallery</Link>*/}
                            {/*    </li>*/}

                            {/*    <li>*/}
                            {/*      <Link to="/pricing">Pricing</Link>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*      <Link to="/faq">Faq</Link>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*      <Link to="/team">Team</Link>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*      <Link to="/team-details">Team Details</Link>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*      <Link to="/shop">Shop</Link>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*      <Link to="/shop-details">Shop Details</Link>*/}
                            {/*    </li>*/}
                            {/*  </ul>*/}
                            {/*  <Link*/}
                            {/*    to="#"*/}
                            {/*    className={`mean-expand ${*/}
                            {/*      show2 && "mean-clicked"*/}
                            {/*    }`}*/}
                            {/*    onClick={() =>*/}
                            {/*      setshow2(show2 === true ? false : true)*/}
                            {/*    }*/}
                            {/*    style={{ fontSize: "18px" }}*/}
                            {/*  >*/}
                            {/*    +*/}
                            {/*  </Link>*/}
                            {/*</li>*/}
                            {/*<li className="has-sub">*/}
                            {/*  <Link to="/blog">Blog</Link>*/}
                            {/*  <ul style={{ display: show3 ? "block" : "none" }}>*/}
                            {/*    <li>*/}
                            {/*      <Link to="/blog">Blog</Link>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*      <Link to="/blog-details">Blog Details</Link>*/}
                            {/*    </li>*/}
                            {/*  </ul>*/}
                            {/*  <Link*/}
                            {/*    to="#"*/}
                            {/*    className={`mean-expand ${*/}
                            {/*      show3 && "mean-clicked"*/}
                            {/*    }`}*/}
                            {/*    onClick={() =>*/}
                            {/*      setshow3(show3 === true ? false : true)*/}
                            {/*    }*/}
                            {/*    style={{ fontSize: "18px" }}*/}
                            {/*  >*/}
                            {/*    +*/}
                            {/*  </Link>*/}
                            {/*</li>*/}
                            <li className="mean-last">
                              <Link to="/contact">Contact</Link>
                            </li>
                          </ul>
                        )}
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Main;
