import React from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../../HomeMain/Bredcrumb'


const Prx = () => {
    return (
        <>
            <Bredcrumb
                title="Prx Behandeling
"
                subtitle="PRX" />

            <div className="container">
                <div className="row justify-content-center align-items-center mt-55" >
                    <div className="service-detail">
                        <div className="content-box">
                            <h2> WAT IS PRX BEHANDELING?
                            </h2>
                            <p>PRX geeft uw huid direct een ongekende Biorevitalisatie (herstel) en levert, zonder bijwerkingen, onmiddellijk duidelijke resultaten. De behandeling zorgt voor een snellere celdeling, vertraagd de verouderingsprocessen en zet de eigen huid aan tot aanmaak van collageen, elastine vezels en vernieuwing.
                            </p> <div className="two-column">
                                <div className="row">
                                    <div className="text-column col-xl-12 col-lg-12 col-md-12">
                                        <figure className="image"><img src="assets/img/blog/prx.jpg"  /></figure>
                                    </div>
                                </div>
                            </div>
                            <h3>Waarom deze behandeling?</h3>
                            <p> Onmiddellijk lifting effect Lange termijn verstevigend effect Gladmakend effect Oplichtend effect Verhelderend effect</p>
                            <div className="two-column">
                                <div className="row">
                                    <div className="image-column col-xl-12 col-lg-12 col-md-12">
                                        <figure ><img src="assets/img/blog/salon3.JPEG" alt="blog" /></figure>
                                    </div>
                                </div>
                            </div>
                            <h3> Behandeling Kosten</h3>
                            <h5 style={{backgroundColor:"rgba(238, 235, 231, 0.4)"}}>PRX Behandeling 45 Minuten €110.00
                            </h5>

                        </div>
                    </div>
                </div>
                <aside className="sidebar services-sidebar">
                    <div className="service-detail-contact wow fadeup-animation" data-wow-delay="1.1s">
                        <h3 className="h3-title">Een behandeling reserveren? </h3>
                        <h3 className="h3-title">Zo Makkelijk en eenvoudig via de link : </h3>
                        <div className="second-header-btn">
                            <a href="https://amani-beauty-skin.salonized.com/widget_bookings/new" className="btn">
                                <i className="fas fa-calendar-alt"></i>
                                Maak Een Afspraak
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </>
    )
}

export default Prx