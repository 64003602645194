import React from 'react'
import Bredcrumb from '../HomeMain/Bredcrumb'
import Appointment from '../HomeMain/Appointment'
import Testimonial from '../HomeMain/Testimonial'
import Service from '../HomeMain/Service'
import Feature from "../HomeMain/Feature";

const Main = () => {
  return (
    <><div style={{backgroundColor:'#FFFCF8'}}>
      <Bredcrumb
        title="Diensten"
        subtitle="Diensten" />
      <div className="service-main-block" style={{backgroundColor:'#FFFCF8'}}>
          <Feature/>
      </div>
      <Appointment />
      <div className="testimonial-block">
        {/*<Testimonial />*/}
      </div>
    </div>
    </>
  )
}

export default Main